import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/what-is-agile-project-management",
  "date": "2013-12-21",
  "title": "WHAT IS AGILE PROJECT MANAGEMENT?",
  "author": "admin",
  "tags": ["development", "agile"],
  "featuredImage": "feature.jpg",
  "excerpt": "Agile project management allows you to produce a smaller amount of deliverables more frequently and efficiently , making it an excellent choice for the teams that are working in the field of  business analysis, product development, programming , and other areas of collaboration."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`But it is a fragile process that requires objectives,  the right scope, and management. In this article, I would like to show you the tools and techniques they need to manage the project successfully through the life cycle of a project. Learn how to use agile projects right and then walk through the four main stages in the cycle, to determine the scope of work and design the structure of the form for requirements gathering, and project management without interfering in the process of rapid construction, and adapt to feedback, and finalize the project.`}</p>
    <p>{`Topics include :`}</p>
    <ul>
      <li parentName="ul">{`What is agile project management?`}</li>
      <li parentName="ul">{`Selecting an agile project`}</li>
      <li parentName="ul">{`Scoping the project`}</li>
      <li parentName="ul">{`Designing your sprint structure`}</li>
      <li parentName="ul">{`Collecting requirements`}</li>
      <li parentName="ul">{`Running stand-up meetings`}</li>
      <li parentName="ul">{`Managing issues and risks`}</li>
      <li parentName="ul">{`Tracking lessons learned`}</li>
      <li parentName="ul">{`Responding to change requests`}</li>
      <li parentName="ul">{`Closing the project`}</li>
      <li parentName="ul">{`Spotting signs of trouble`}</li>
    </ul>
    <h2>{`What is agile project management?`}</h2>
    <p>{`Agile Project Management is the process by which projects can be managed and implemented in small chunks of work. Agile projects deliver value to the business in frequent small deliveries of product called ###  features. In the traditional Waterfall Methodology, the requirements for the project would be documented upfront. Then the design of the whole solution would be completed, followed by the Development, Testing, and finally Implementation of the product. If this whole process takes a year to complete, the business does not see any tangible value until the very end of that project.`}</p>
    <h3>{`Sprints`}</h3>
    <p>{`With agile projects, items are created via small logical chunks of work called iterations or ###  sprints. Agile is an excellent methodology to use when business needs are frequently changing, or when the business wants to reap product benefits earlier. With Agile you can focus on what the business needs now, and if that changes, the new business needs can be accommodated in the next iteration. Agile is frequently used to manage IT projects, but can also be used to manage non-IT projects.`}</p>
    <p>{`Examples of non-IT projects that are suitable for Agile are facility moves, company reorganizations, or changing business processes within a department. Just about any project can utilize Agile if deliverables can be produced and implemented in a short period, and can be expanded or added to with future capabilities. Just like building blocks coming together, Agile projects build capabilities one block or a few blocks at a time.`}</p>
    <p>{`Sprints (iterations) are typically 2 to 12 weeks long.`}</p>
    <h3>{`Communication,  minimal documentation`}</h3>
    <p>{`Face to face communication is emphasized over documentation. We want to produce a product, not product documentation. As the business requirements change, the documents become redundant. Business and technical team members are co-located or use feature-rich virtual tools to simulate being together.  In addition to these, a ###  sponsor who is 100% committed to the Agile process plays a vital role.`}</p>
    <h3>{`Accommodate requirement changes`}</h3>
    <p>{`And lastly, requirement changes are anticipated and accommodated. There are other items required for agile projects to work that do not differ from traditional projects. These include having a vision for the endgame, following a universally understood project lifestyle, and the requirements must be understood. You should use a shared and managed schedule and you have a dedicated team that is focused on getting the job done.`}</p>
    <p>{`And lastly, communication with all stakeholders is critical. There are several Agile methodologies and processes in use today. For this article, we will describe and explain an agile methodology that uses the following lifecycle stages. Envision defines the boundaries of the project. You then loop through the Speculate, Explore and Adapt stages. And you perform a Close phase once at the end.`}</p>
    <p>{`Now that you've heard about the common characteristics and benefits of an agile project, let's go deeper and explore all of these in greater detail.`}</p>
    <h2>{`Overview of the agile life cycle`}</h2>
    <p>{`Agile projects are conducted in five stages, called the Agile Life Cycle. The stages are Envision, Speculate, Explore, Adapt, and Close. Let's take a look at the highlights of each of these stages.`}</p>
    <h3>{`Envision Stage`}</h3>
    <p>{`During the Envision stage, you and your customer will determine what it is you're trying to build. You will also confirm who needs to be on your team and determine the team values and norms you're going to use. You go through the Envision stage of an Agile project once. At the end of the Envision stage, you should have a project document describing your scope and overall objectives and your defined stakeholders for the project. You should also have your team collaboration tools set up and working. And you should have established team norms. Examples of team norms are your working hours and how the team will work together to solve issues. You will then cycle through the next three stages, Speculate, Explore, and Adapt to complete each sprint.`}</p>
    <h3>{`Speculate Stage`}</h3>
    <p>{`The Speculate stage is a planning exercise. During the Speculate stage, you will develop or revise the feature-based delivery plan, estimates for each feature and risks you need to manage. A feature is a piece of functionality or outcome that is valued by the client. One or more features are completed as part of each sprint.`}</p>
    <p>{`At the end of the Speculate stage, you should have a set of requirements for the sprint, and a list of features to be developed based on those requirements. You will also create effort estimates for each feature, and your risks will be identified or updated for the features you are working on.`}</p>
    <h3>{`Explore Stage`}</h3>
    <p>{`During the Explore stage, you develop the product.  Activities during the Explore stage include your daily stand-up meetings and frequent and brutally honest peer reviews of the features as they are developed. The reviews come from daily, or nearly daily interactions between business and technical personnel, and frequent and focused testing.`}</p>
    <h3>{`Adapt Stage`}</h3>
    <p>{`Now that the features for the iteration have been developed, it's time to pause and reflect. This is the purpose of the Adapt stage. A great benefit of Agile is you get feedback frequently, it's easy to remember what worked and what did not, fix things quickly and move forward.`}</p>
    <p>{`Common activities during the Adapt stage include a final review of the features by the customer and a documented meeting of team members to reflect on their performance. From these, lessons are captured and shared, and future sprint plans are reviewed and adjusted. The Adapt stage can happen very quickly, often being completed in just one day. The project will now loop through the Speculate, Explore and Adapt stages until all sprints for the project are completed.`}</p>
    <h3>{`Close Stage`}</h3>
    <p>{`Once all the iterations are complete, or features are implemented, the Close stage can occur. During this stage, you ensure all of your deliverables are completed and a final set of lessons learned are captured for the project. Now that you know what is supposed to happen during each Agile stage, during the next set of videos, we will describe how to make it all happen.`}</p>
    <h3>{`The Envision phase`}</h3>
    <p>{`The Envision Phase is the first of five phases in the Agile life cycle and provides the foundation for the project. At the end of the Envision Stage, you should have a documented project charter describing your scope, and overall objectives and your defined stakeholders for the project. You should also have your team collaboration tools set up and working, and you should have established team norms. Let's go into the details for each of these items. The project charter should contain the scope of the project. The scope creates the overall boundaries for the project.`}</p>
    <p>{`Included in the scope should be the product vision, which is a summary statement about the final product. This includes the target customer, key benefits, and purpose of the project, such as cost savings or competitive advantage. Also, the project charter should identify the project sponsor and project manager responsibilities, authorize the project manager to perform their responsibilities, and define the level of authority given to the project manager. Team collaboration tools are essential to ensure project communication is easy and understood by all team members.`}</p>
    <p>{`These tools can track and report status, facilitate joint feature development, and push information out to team members. The key is having tools that are easy to use. There are many collaboration tools on the market. The size of the project, the number of stakeholders, and the amount of collaboration desired will determine how advanced you need to get with your collaboration tools. My suggestion is to start with a small number of low complexity tools and add other tools if and when they are required. During the Envision phase, all stakeholders will start working together on the overall design in the project.`}</p>
    <p>{`At this time, it's important to establish the team norms on how the team will work together, including where they will physically work. As Agile projects involve close collaboration, the team must understand how it will operate. That is the critical element of team norms. Example team norms include, actively listen to what others are saying, attack the problem, not the person. Seek to understand first, only focus on this sprint and feature.`}</p>
    <p>{`If you see a problem say something and make suggestions for resolution. Actively participate in the daily meetings, be engaged, solve conflicts with the other person if possible. If not achievable, both parties must come forward for help from management. Email is a low touch communication vehicle, and should not be used to solve problems. Don't respond to text messages during meetings. Provide your full attention to the matter at hand.`}</p>
    <p>{`Be respectful of one another. Have the best interest of the project as your priority. Share and respect the roles and responsibilities of each team member. Agile teams are best if they have 15 or fewer people. Larger teams are possible, but I recommend you split them into smaller subteams to stay within the 15 or less guideline. Larger teams introduce risk. As the coordination between larger numbers of people and the features they produce is much more difficult.`}</p>
    <p>{`Most of the risk with Agile projects comes from having too ambitious a schedule for feature production. Or not having people on the team that can make decisions without waiting for management approval. These situations should be avoided. With a proper project charter documented, collaboration tools ready to use, and the team in place with minimal risk elements, the project is now well-positioned to commence the planning for the first iteration in the first speculate phase.`}</p>
    <h2>{`The Speculate phase`}</h2>
    <p>{`The primary purpose of the speculate phase is for the business and technical teams to identify the features for this iteration. If this is not the first iteration, you should also need to review past features that have not been completed. So in the speculate stage, you and the business need to consider new features. Features from the backlog list, the list of features on your to-do list and features not completed from the prior iteration which get added to the backlog list.`}</p>
    <p>{`Before we move forward, let's review the characteristics of a feature. A feature is similar to a requirement but instead focuses on a specific business need. A feature is a small client-valued function expressed in the form, action, result, that allows the user to satisfy a business objective or need. To show you what I mean, here are some examples of features. Calculate tax for supplies ordered, display the name and address of buyer on the invoice, display the shipping name and address on the invoice.`}</p>
    <p>{`Enrol the student in a course. And track course completions. The typical and simple agile approach is to write each feature on an index card or a big sticky note. Using a physical card that you can move from one category to another and back again can make organizing and prioritizing very easy. For larger projects, Agile collaboration tools can simulate the index card approach. Once you have a complete set of features, organize them logically into groups.`}</p>
    <p>{`You can then have the business review and prioritize features with advice from the technical team. During this process business stakeholders typically ask questions about existing features and add some features as well. This conversation about additional features is great and is part of the agile process. As is prioritizing all these good ideas. If yours is like most agile projects, you will have discussions about features that have to be considered in a future project. Because you'll run out of money, time or both.`}</p>
    <p>{`Once the feature list has been reviewed, prioritized and agreed by the business and the sponsor, you are ready to roll. Remember, you get to review the feature list at the beginning of each iteration, so you're only done for now. Now that the features have been agreed, you'll need to estimate the work effort required to complete each feature. If this is your first iteration, you should do the estimates for all features. Make sure you work with the team members and business subject matter experts to obtain accurate estimates.`}</p>
    <p>{`Once this is completed, you can now create your Iteration, Milestone and Release Plan. The Iteration, Milestone and Release Plan will list all features to be completed, when they will be completed and when the features will be implemented for the business. Typically, the speculate phase does not take a long time. For example, if your iteration is eight weeks total, you should only be spending approximately five days or so in the speculate phase. For any agile project, however, the first iteration of the speculate phase will take longer.`}</p>
    <p>{`As you'll need to identify and estimate the features for the whole project. Not just the first iteration. The speculate phase is about careful planning for each iteration and reprioritizing new features along with a backlog. This considered planning helps you keep the next stage, explore, sharp and focused.`}</p>
    <h2>{`The Explore phase`}</h2>
    <p>{`Here it is, the phrase most people get excited about, we now get to produce the product, fortunately, with Agile it doesn't take very long to go from the Envision and Speculate phases to the Explore phase, this phase is all about collaboration between the business and technical team members. The daily stand-up meetings, although run throughout all agile project stages, play their most substantial role during the Explore phase. Let's examine what typical stand-ups are like.`}</p>
    <p>{`A meeting should be around 15 minutes long, 30 minutes max. Each team member shares what they achieved yesterday. What they plan to achieve today and they discuss anything they need help with to progress their work. The stand-up meeting is not the place to resolve issues. If issues are identified they should be noted, addressed after the stand-up meeting and reported back to the group the next day. That's it, nothing to document other than items you may want to put into an issues register, for building lessons learned.`}</p>
    <p>{`So what does the project manager do in these meetings? The project manager fulfils an observer role, allowing the team to take the lead. As project manager, you should watch for issues that are not being resolved, and remove roadblocks for the team. Also, you should ensure risks are decreasing over time and after the standups communicate status to key stakeholders so they are informed of progress. Listen carefully during the standup meetings. Your job during the Explore stage is to protect and enhance the productivity of each team member.`}</p>
    <p>{`By letting them get on with the job of building and handle the organizational distractions that may slow them down. The stand-up meetings help you understand how to do this by giving you a view of what the team is facing daily. But also gives you the basis to maintain control of the project. Tracking progresses against the planned features for this sprint is your control mechanism. Completed features are noted in the standouts as well as on a feature board in the team room, so the team can see the progress that has been achieved.`}</p>
    <p>{`If features are behind schedule, find out why? Make adjustments as quickly as possible and take good notes so lessons learned can be applied. Similar to non-agile projects maintaining an issue register is necessary. What is important is that issues are being resolved in a timely fashion. Your issues register will provide the mechanism to determine if open issues are growing in volume. This could be an indication that something is wrong and changes should be considered. It is easy for teams to get so caught up in building the product that time gets away from everyone.`}</p>
    <p>{`Timekeeping and sprint schedule tracking is essential. End each Explore phase when the plan features for the sprint have been completed or when the time allocated for this Explore phase has been consumed. You must end the phase under one of these two conditions. If you plan to be in the Explore phase for seven weeks, and the plan features are not complete, stop the phase according to your schedule and proceed to the Adapt phase. At that time, capture and apply lessons learned.`}</p>
    <p>{`Ensure all team members understand the lessons learned, changes, and set or adjust expectations with your stakeholders. That is the purpose of the Adapt phase which follows Explore.`}</p>
    <h2>{`The Adapt and Close phases`}</h2>
    <p>{`The adapt phase occurs at the end of each sprint immediately following the explore phase. In each adapt phase you will review with the team what has been delivered compared to what was planned for the sprint, discuss what is and is not working and agree to any changes that will be applied. It's also wise to review the prior adapt phase lessons learned to see if trends are occurring. The adapt phase is also the time to review the product with the customer and confirm if features are working as expected. Determine if anything has changed in the business that reduces or changes the effectiveness of the features.`}</p>
    <p>{`You'll also want to take the time to verify that features are producing the intended business benefits. You must facilitate a lessons learned discussion in each adept phase for feedback to be openly shared, all input is valuable and should be considered. You can then brainstorm ideas to resolve issues or eliminate potential roadblocks to your teams' success. You will find several typical adjustments result from these adapt phase feedback sessions, these adjustments include, adding or removing features, adjusting the estimates for features or reprioritizing the features on the backlog list, modifying the daily stand up meetings agenda to maximize effectiveness.`}</p>
    <p>{`Changing team members based on project need, availability, and teaming effectiveness, updating the risk register, modifying and removing processes that are not effective, and lastly, adding processes, but only if they're essential to progress the project. It's important to let all stakeholders know about the changes that will be applied to future sprints, along with why those changes are being made. If the team has been working especially hard, this is the perfect opportunity to celebrate the successes of the prior sprints, and acknowledge specific achievements.`}</p>
    <p>{`Some team members may need a mental break before they start the next sprint. As project manager, you'll need to ensure the team is ready for the next sprint and the morale is positive and ready to go. For longer projects, I suggest occasionally giving team members a holiday from a whole sprint to allow them to complete other work activities or take vacation. This allows them to return to the project rejuvenated. Assuming this is not the end of the project, when you complete the adapt phase you'll proceed to the speculate phase for the next sprint.`}</p>
    <p>{`Remember if the original agreed cost or schedule have been consumed it'snow time to end the project and proceed to the close phase. If this happens to be your last sprint the project is now ready to be closed. The Close Phase now begins. The administrative activities in the Close Phase include ensuring vendors have been invoiced and payments received. Reconciling any project financials, working with managers and team members to redeploy people to other projects or work activities.`}</p>
    <p>{`Communicating the overall project results to the project team and other key stakeholders. Now's the time to toot your horn. Next, ensure business benefits continue to be monitored and achieved. It is important to transition this monitoring to the customer. And last. If Agile project management is new to your company, provide a special recap on why the Agile process worked and what lessons you learnt. You should resist the temptation to skip or shorten the close phase activities. Project closure is important to ensure the project is not lingering unnecessarily.`}</p>
    <p>{`That also gives everyone the chance to appreciate the significance of what has been achieved.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      